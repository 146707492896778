<template>
  <div class="headerArrow" ref="wrapper">
    <div class="unit" v-for="(item) of list" :key="item.id"
      :style="{ backgroundImage: type == item.id ? 'url(' + require('@/assets/images/step_arrow_current' + (screenWidth >= 768 ? '' : '_sp') + (sex == 1 ? '_m' : '') + '.png') + ')' : 'url(' + require('@/assets/images/step_arrow' + (screenWidth >= 768 ? '' : '_sp') + (sex == 1 ? '_m' : '') + '.png') + ')', color: type == item.id ? '#fff' : sexStyleType.color01 }">
      <p class="p1"><span>{{ item.text1 }}<br class="mq" />{{ item.text2 }}</span></p>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    type: {
      default: 0,
    },
  },
  data() {
    return {
      screenWidth: 0,
      bgImage: '',//当前步骤背景图
      bgImage2: '',//非当前步骤背景图
      list: [{
        text1: 'クリニック・日時を',
        text2: '選ぶ',
        id: 0,
      }, {
        text1: '患者さま情報の',
        text2: 'ご入力',
        id: 1,
      }, {
        text1: '患者さま情報の',
        text2: 'ご確認',
        id: 2,
      }]
    }
  },
  computed: {
    ...mapState(["sexStyleType", "sex"]),
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.$nextTick(() => {
          this.screenWidth = document.body.clientWidth;
        })
      })()
    }

  },
};
</script>
<style lang="scss" scoped>
.headerArrow {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: space-between;

  .unit {
    width: 32%;
    background-repeat: no-repeat;
    background-size: contain;

    p {
      width: 100%;

      span {
        width: 100%;
      }
    }
  }

  .unit:nth-child(2) {
    margin: 0 30px;
  }

  .is-current {
    color: #fff;
  }

  br.mq {
    display: none;
  }
}


@media screen and (max-width: 768px) {
  br.mq {
    display: block !important;
  }

  .unit:nth-child(2) {
    margin: 0 20px !important;
  }

  .unit {
    p {
      span {
        position: absolute;
        width: auto !important;
        top: -2px !important;
        left: -4px !important;
        display: block;
        transform: scale(0.9) !important;
        width: 105px !important;
      }
    }
  }
}
</style>